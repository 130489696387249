@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.header{
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  @include tablet {
    padding: 1rem 2.5rem;
  }
  @include desktop{
    padding: 2rem 8.125rem;
  }
  @include largeDesktop{
    padding: 2rem 20rem;
  }
  &__logo{
    @include desktop{
      height: 3.5rem;
      width: 3.5rem;
    }
  }
  &__links{
    @include tablet{
      display: flex;
    }
  }
  &__link{
    display: none;
    @include tablet{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2.5rem;
      text-decoration: none;
    }
  }
  &__anchor{
    @include tablet{
      text-decoration: none;
      color: $portfolio-yellow;
    }
  }
  &__icon{
    margin-right: 0.25rem;
  }
  &__sidebar{
    @include tablet{
      display: none;
    }
  }
  &__resume{
    @include tablet{
      padding: 0.5rem 0.75rem;
      border: none;
      border-radius: 0.5rem;
      background-color: $portfolio-yellow;
      cursor: pointer;
      &:hover{
        transition-duration: 0.2s;
        background-color: $portfolio-darkyellow;
      }
    }
   
  }
}

.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: none;
  &--open{
    @extend .backdrop;
    display: block;
  }
}