@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

@keyframes wave {
      0% {
          transform: rotate(0.0deg)
        }
      
        10% {
          transform: rotate(14.0deg)
        }
      
        20% {
          transform: rotate(-8.0deg)
        }
      
        30% {
          transform: rotate(14.0deg)
        }
      
        40% {
          transform: rotate(-4.0deg)
        }
      
        50% {
          transform: rotate(10.0deg)
        }
      
        60% {
          transform: rotate(0.0deg)
        }
  
        100% {
          transform: rotate(0.0deg)
        }
}

.landing{
  padding: 1rem;
  @include borderBottom;
  @include tablet{
    padding: 0;
  }

  &__container{
    opacity: 0;
    transition: all 1.5s;
    @include borderBottom;
    @include tablet{
      display: flex;
      padding: 1rem 2.5rem 0 2.5rem;
    }
    @include desktop{
      padding: 2rem 8.125rem 0 10.9375rem;
      justify-content: center;
    }
    @include largeDesktop{
      padding: 2rem 30rem 0 30rem;
    }
    &--animate {
      @extend .landing__container;
      opacity: 1;
    }
  }
  &__header{
    @include page-header;
  }
  &__subheader{
    @include landing-subheader;
    @include desktop{
      font-size: 1.75rem;
      line-height: 2.5rem;
      max-width: 90%;
    }
  }
  &__focus{
    font-size: 0.875rem;
    @include tablet{
      font-size: 1rem;
    }
  }
  &__animate{
    animation-name: wave;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
  }
  &__headercontainer{
    @include tablet{
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include desktop{
      width: 50%;
      margin-right: 2rem;
    }
  }
  &__imagecontainer{
    display: none;
    @include tablet{
      max-width: 35vw;
      display: flex;
      align-items: flex-end;
    }
    @include desktop{
      max-width: 25vw;
    }
  }
  &__image{
    width: 100%;
    height: auto;
  }
  &__sectionheader{
    @include section-header;
    @include tablet{
      margin: 2rem 0 1.5rem 0;
    }
    @include desktop{
      font-size: 1.75rem;
      margin: 4rem 0 2rem 0;
    }
    @include largeDesktop{
      font-size: 2rem;
    }
  }
  &__projects{
    @include tablet{
      padding: 0 4.25rem;
    }
    @include desktop{
      padding: 0 8.125rem 4rem 8.125rem;
    }
    @include largeDesktop{
      padding: 2rem 20rem 8rem 20rem;
    }
  }
  &__projectcontainer{
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: scale(0.8);
    transition: all 1s;
    @include desktop{
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &--animate{
      @extend .landing__projectcontainer;
      opacity: 1;
      transform: scale(1);
    }
  }
  &__project{
    position: relative;
    width: 100%;
    background-color: $portfolio-black;
    padding: 0.5rem;
    border-radius: 0.625rem;
    margin-bottom: 2rem;
    @include tablet{
      padding: 1rem;
      margin-bottom: 3rem;
    }
    @include desktop{
      width: 48%;
    }
  }
  &__projectimage{
    width: 100%;
    height: auto;
  }
  &__title{
    @include section-header;
  }
  &__description{
    @include page-body;
  }
  &__stack{
    @include page-label;
  }
  &__overlay{
    border-radius: 0.625rem;
    @include overlay;
  }
}