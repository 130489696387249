//neutral colors
$portfolio-white: #F8FAFC;
$portfolio-grey700: #334155;
$portfolio-black: #1E293B;

//primary colors
$portfolio-darkblue:#001935;
$portfolio-blue900:#002651;

//secondary colors
$portfolio-yellow: #FFC459;
$portfolio-darkyellow: #FAA719;

//tertiary colors
$portfolio-purple:#7159FF;
$portfolio-blue:#1292EE;