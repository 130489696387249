@use './styles/partials/colors' as *;
@use './styles/partials/fonts' as *;
@use './styles/partials/mixins' as *;
@use './styles/partials/variables' as *;

*,
*::before,
*::after{
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-weight: 400;
  color: $portfolio-white;
  background-color: $portfolio-darkblue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
