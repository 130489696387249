@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.work{
  padding: 1rem;
  @include borderBottom;
  @include tablet{
    padding: 1rem 4rem;
  }
  @include desktop{
    padding: 2rem 8.125rem;
  }
  @include largeDesktop{
    padding: 4rem 20rem;
  }
  &__title{
    @include page-header;
  }
  &__imagecontainer{
    position: relative;
    @include slideIn-before;
    @include desktop{
      display: flex;
      justify-content: center;
    }
    &--animate{
      @extend .work__imagecontainer;
      @include slideIn-after;
    }
  }
  &__imagelink{
    @include desktop{
      max-width: 75%;
    }
    @include largeDesktop{
      max-width: 60%;
    }
  }
  &__image{
    width: 100%;
    height: auto;
  }
  &__subtitle{
    @include section-header;
    margin-top: 1.5rem;
    @include desktop{
      margin-top: 3rem;
    }
  }
  &__stack{
    @include slideIn-before;
    &--animate{
      @extend .work__stack;
      @include slideIn-after;
    }
  }
  &__paragraphs{
    @include slideIn-before;
    &--animate{
      @extend .work__paragraphs;
      @include slideIn-after;
    }
  }
  &__paragraph{
    @include page-body;
    @include tablet{
      font-size: 1.25rem;
    }
    &--frontend{
      color: $portfolio-purple;
    }
    &--backend {
      color: $portfolio-blue;
    }
    &--others{
      color: $portfolio-yellow;
    }
    &--none{
      display: none; 
    }
  }
  &__links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    @include slideIn-before;
    &--animate{
      @extend .work__links;
      @include slideIn-after;
    }
  }
  &__linktag{
    &--none{
      display: none;
    }
  }
  &__link{
    padding: 0 1rem;
  }
}