@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;


@keyframes animateRocket {
  0%{
    transform: scale(1) rotate(45deg);
  }
  10%{
    transform: scale(1.25) rotate(90deg);
  }
  20%{
    transform: scale(1.5) rotate(135deg);
  }
  100%{
    transform: scale(20) translate3d(0vw, 100vh, 0) rotate(135deg);
  }
}

.about{
  padding: 1rem;
  @include borderBottom;
  @include tablet{
    padding: 1rem 4rem;
  }
  @include desktop{
    padding: 2rem 8.125rem;
  }
  @include largeDesktop{
    padding: 4rem 20rem;
  }
  &__heading{
    @include page-header;
  }
  &__icon{
    display: inline-block;
    margin-left: 1rem;
    cursor: pointer;
    &:hover{
      transform: scale(1.2);
      transition-duration: 0.2s;
    }
    &--animate{
      @extend .about__icon;
      position: relative;
      z-index: 10;
      animation-name: animateRocket;
      animation-duration: 3s;
    }
  }
  &__imagecontainer{
    @include tablet{
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
    }
  }
  &__image{
    width: 100%;
    height: auto;
    @include slideIn-before;
    @include tablet{
      width: 50vw;
    }
    @include desktop{
      max-width: 30vw;
    }
    @include largeDesktop{
      max-width: 22vw;
    }
    &--animate{
      @extend .about__image;
      @include slideIn-after;
    }
  }
  &__paragraph{
    @include page-body;
    line-height: 1.45rem;
    @include slideIn-before;
    &--animate{
      @extend .about__paragraph;
      @include slideIn-after;
    }
  }
  &__current{
    padding: 1rem;
    width: 100%;
    background-color: $portfolio-black;
    border-radius: 0.625rem;
    @include desktop{
      margin: 4rem 0;
      width: 60%;
    }
  }
  &__title{
    @include section-header;
    margin: 1rem 0 0 0;
  }
  &__label{
    @include page-label;
    @include slideIn-before;
    @include desktop{
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    &--animate{
      @extend .about__label;
      @include slideIn-after;
    }
  }
  &__listcontainer{
    width: 70%;
    display: flex;
    justify-content: space-between;
    @include tablet{
      width: 55%;
    }
    @include desktop{
      width: 70%;
    }
  }
  &__ulist{
    list-style: none;
    padding: 0;
  }
  &__list{
    @include page-body;
    display: flex;
    padding: 0.5rem 0;
  }
  &__bulletpoint{
   margin-right: 0.5rem; 
  }
  &__subtitle{
    @include page-body;
    font-weight: 600;
    margin: 1.5rem 0 1rem 0;
    @include desktop{
      margin: 2.5rem 0 1rem 0;
    }
  }
  &__hobbiescontainer{
    @include slideIn-before;
    @include desktop{
      margin: 4rem 0;
    }
    &--animate{
      @extend .about__hobbiescontainer;
      @include slideIn-after;
    }
  }
  &__skills{
    @include slideIn-before;
    @include desktop{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &--animate{
      @extend .about__skills;
      @include slideIn-after;
    }
  }
  &__stack{
    @include desktop{
      width: 45%;
    }
  }
  &__description{
    @include page-body;
    line-height: 1.45rem;
  }
}