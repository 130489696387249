@use '../../styles/partials/colors' as *;
@use '../../styles/partials/fonts' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.footer{
  padding: 1rem;
  display: flex;
  flex-direction: column;
  @include tablet{
    padding: 1rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @include desktop{
    padding: 2rem 8.125rem;
  }
  @include largeDesktop{
    padding: 2rem 20rem;
  }
  &__paragraph{
    @include page-label;
    @include tablet{
      text-align: center;
    }
    @include desktop{
      width: 70%;
    }
    &--bold{
      font-weight: 500;
      @include tablet{
        font-size: 1.25rem;
      }
    }
  }
  &__linkcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
  }
  &__link{
    text-decoration: none;
  }
  &__button{
    @include primarybtn;
  }
  &__socials{
    @extend .footer__linkcontainer;
    @include tablet{
      flex-direction: column;
      position:fixed;
      bottom: 50vh;
      right: 0rem;
      background-color: $portfolio-black;
      border-radius: 0.625rem;
    }
  }
  &__icon{
    padding: 1rem;
    @include tablet{
      padding: 0.75rem;
    }
  }
  &__copyright{
    @include footer-label;
    text-align: center;
    margin-top: 1.5rem;
    @include tablet{
      margin-top: 4rem;
    }
  }

}