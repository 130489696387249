@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Bold.woff') format('woff'),
    url('../../assets/fonts/Roboto-Bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Regular.woff') format('woff'),
    url('../../assets/fonts/Roboto-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Roboto-Medium.woff') format('woff'),
    url('../../assets/fonts/Roboto-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}